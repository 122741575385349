.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
  }
  
  .icon {
    font-size: 2rem; /* Ajusta el tamaño del ícono */
    margin-bottom: 8px;
  }
  
  .value {
    font-size: 2rem; /* Ajusta el tamaño del valor */
    font-weight: bold;
    color: #1E90FF; /* Color principal para el valor */
  }
  
  .unit {
    font-size: 1.2rem; /* Ajusta el tamaño de la unidad */
    color: #1E90FF; /* Color más tenue para la unidad */
  }
  