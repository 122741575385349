.card {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card_content {
  padding: 0rem;
  border: 1px solid #DBDDE1;
  border-radius: 14px;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_content button:not([title*="Go to"]) {
  font-family: 'Montserrat';
  color: #0074fd;
}

.card_content [aria-label="Search"] >div {
  font-family: 'Montserrat';
  border: 1px solid #919191;
  border-radius: 20px;
  padding: 0 25px 0 10px;
}

.card_content [aria-label="Search"] >div:before,
.card_content [aria-label="Search"] >div:after,
.card_content [aria-label="Search"] >div:hover::before,
.card_content [aria-label="Search"] >div:hover::after,
.card_content [aria-label="Search"] >div:focus::before,
.card_content [aria-label="Search"] >div:focus::after {
  border: none;
}

.card_content [aria-label="Search"] >div [data-testid="SearchIcon"] {
  position: absolute;
  right: 10px;
}

.card_content [aria-label="Search"] >div button {
  color: #919191;
}

.header {
  padding: 5px 15px;
  box-shadow: 0 2px 8px rgba(219, 221, 225, 0.6);
  background-color: #455072;
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 0.8rem;
  font-family: Montserrat;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react_datepicker_input {
  width: unset;
  padding: 0 15px 0 5px;
  display: inline-block;
}

.react_datepicker_input input {
  border: 1px solid #DBDDE1;
  border-radius: 20px !important;
  box-shadow: none !important;
  outline: none;
  padding: 5px 25px 5px 20px !important;
  font-size: 16px;
  font-family: Montserrat;
  text-align: right;
  width: 145px;
}

/* Estilos para el componente DateRangePicker */
.dateRangePicker_input {
  width: unset;
  padding: 0 15px 0 5px;
  display: inline-block;
}

.dateRangePicker_input input {
  border: 1px solid #DBDDE1;
  border-radius: 20px !important;
  box-shadow: none !important;
  outline: none;
  padding: 5px 25px 5px 20px !important;
  font-size: 16px;
  font-family: Montserrat;
  text-align: right;
  width: 215px; /* Aumentar el ancho del input */
}


.filterContainer {
  position: relative;
}

.filterContainer .filterDialog {
  display: none;
}

.filterContainer:hover .filterDialog,
.filterContainer:focus .filterDialog {
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  background-color: #919191;
  padding: 5px 10px;
  border-radius: 20px 0 20px 20px;
  width: max-content;
  z-index: 2;
}

.filterContainer:hover .filterDialog button,
.filterContainer:focus .filterDialog button {
  padding: 0;
  margin-left: 10px;
  color: white;
}

.filterContainer:hover .filterDialog button svg,
.filterContainer:focus .filterDialog button svg {
  height: 15px;
}