.card {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card_content {
  padding: 0rem;
  border: 1px solid #DBDDE1;
  border-radius: 14px;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_content button:not([title*="Go to"]) {
  font-family: 'Montserrat';
  color: #0074fd;
}

.card_content [aria-label="Search"] >div {
  font-family: 'Montserrat';
  border: 1px solid #919191;
  border-radius: 20px;
  padding: 0 25px 0 10px;
}

.card_content [aria-label="Search"] >div:before,
.card_content [aria-label="Search"] >div:after,
.card_content [aria-label="Search"] >div:hover::before,
.card_content [aria-label="Search"] >div:hover::after,
.card_content [aria-label="Search"] >div:focus::before,
.card_content [aria-label="Search"] >div:focus::after {
  border: none;
}

.card_content [aria-label="Search"] >div [data-testid="SearchIcon"] {
  position: absolute;
  right: 10px;
}

.card_content [aria-label="Search"] >div button {
  color: #919191;
}

.devices {
  align-items: center;
  max-height: 50rem;
  margin: 0 auto;
  padding: 0;
  animation: devices-appear 1s ease-out forwards;
}

.grid_side_section_positioning {
  align-items: center;
  max-height: 50rem;
  margin: 0 auto;
  padding: 0;
  animation: devices-appear 1s ease-out forwards;
  height: 50%;
}

.messages {
  padding: 15px;
}

.devices ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@keyframes devices-appear {
    from {
      opacity: 0;
      transform: translateY(3rem);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


img {
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.MuiDataGrid-columnHeaders{
  display: none;
}

.vl {
  border-left: 6px solid green;
  height: 100%;
}
