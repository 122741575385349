/* DataTableHistory.module.css */
.card {
    /* height: 100%; */
    /* min-height: 370px; */
}

.card_content {
    padding: 1rem;
    color: #424242;
}

.references {
    display: inline-flex;
}

.references >div >div {
    margin-right: 5px;
    width: 45px;
}

.filters {
    display: inline-flex;
    align-items: center;
}

.filters input {
    cursor: pointer;
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 100%;
    vertical-align: middle;
    border: 1px solid #919191;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
}

.filters input:checked {
    background-color: #919191;
}

.filters label {
    display: inline-flex;
    margin-right: 15px;
    font-family: Montserrat;
    cursor: pointer;
    align-items: center;
}

.filters label svg {
    height: 16px;
}

.filters {
    display: inline-flex;
    align-items: center;
}

.bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.dataGridRoot {
    border: 2px solid #DBDDE1;
    border-radius: 14px;
    height: 100%;
    width: 100%;
}

.dataGridCell {
    color: #424242; /* Color de texto para las celdas */
}

.dataGridColumnHeader {
    background-color: #455072; /* Color de fondo para los encabezados de columna */
    color: #ffffff; /* Color de texto para los encabezados */
}

.selectedRow {
    background-color: #e0e0e0; /* o cualquier color que quieras para la fila seleccionada */
  }