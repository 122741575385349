.icon_container {
    position: relative;
}

.dialog {
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 16px;
    color: black;
    background: white;
    border-radius: 0 0 5px 5px;
    text-align: left;
    width: 300px;
    border: 1px solid #DBDDE1;
}

.noti_container_read {
    border-bottom: 1px solid #DBDDE1;
    padding: 10px 15px;
    font-family: 'Roboto_Light';
}

.noti_container_unread {
    border-bottom: 1px solid #DBDDE1;
    padding: 10px 15px;
    font-family: 'Roboto_Light';
    background-color: #d8e1ff;
}

.noti_container_read:first-child,
.noti_container_unread:first-child {
    padding-top: 20px;
}

.noti_container_read:last-child,
.noti_container_unread:last-child {
    border: none;
    padding-bottom: 20px;
}

.date_container {
    margin-top: 5px;
    text-align: right;
    color: #b6b8ba;
}

.eliminar_texto {
    margin: 5px 15px;
    color: #455072;
    font-size: medium;
    font-weight: 400;
    font-family: Roboto;
}